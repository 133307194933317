<div class="auth-sidebar-content z-fx-col z-fx-auto z-fx-gap-32">
  <div *ngIf="showInfo" class="z-fx-col z-fx-gap-48 z-fx-auto">
    <div class="z-fx-col z-fx-gap-8">
      <span *ngIf="inviteData?.email" class="zef-text-h2" i18n>{{ inviteData?.email }}</span>
      <span class="zef-text-p2 zef-color-muted" i18n>Log into your account</span>
    </div>

    <div class="z-fx-col z-fx-gap-16">
      <team-tile
        variant="small"
        [teamName]="inviteData.teamName"
        [teamLogo]="inviteData.teamLogo"
        [teamCreated]="0"
      ></team-tile>

      <span class="zef-text-p1" i18n>
        You are invited by
        <strong>{{ inviteData?.userName }}</strong>
        to join their organization.
      </span>
    </div>

    <button mat-raised-button color="ink" class="zef-stretch-button email-signup" (click)="showInfo = false" i18n>
      Log into your account
    </button>
  </div>

  <div *ngIf="!showInfo" class="z-fx-col z-fx-gap-48 z-fx-auto">
    <div class="z-fx-col z-fx-gap-8">
      <span *ngIf="inviteData?.email" class="zef-text-h2" i18n>{{ inviteData?.email }}</span>
      <span class="zef-text-p2" i18n>
        Has an invitation to join
        <strong>{{ inviteData.teamName }}</strong>
      </span>
    </div>

    <div class="flex flex-col gap-16">
      <div class="z-fx-col z-fx-gap-16">
        <button
          mat-raised-button
          color="secondary"
          class="zef-stretch-button auth-provider-button"
          (click)="signInWithGoogle()"
        >
          <div class="flex w-full items-center gap-8">
            <img [src]="'assets/logos/google.png'" alt="google" />
            <strong class="zef-text-p2 flex-auto" i18n>Sign in with Google</strong>
          </div>
        </button>

        <div class="z-fx-col z-fx-gap-16">
          <button
            mat-raised-button
            color="secondary"
            class="zef-stretch-button auth-provider-button"
            (click)="signInWithMicrosoft()"
          >
            <div class="flex w-full items-center gap-8">
              <img [src]="'assets/logos/microsoft.png'" alt="microsoft" />
              <strong class="zef-text-p2 flex-auto" i18n>Sign in with Microsoft</strong>
            </div>
          </button>

          <div *ngIf="authError?.code === AuthError.ACCOUNT_NOT_EXIST" class="zef-text-p1 auth-error-text" i18n>
            Account with {{ authError.message }} does not exist
          </div>
          <div
            *ngIf="authError?.code === AuthError.GOOGLE_PROVIDER_ERROR && authError.message"
            class="zef-text-p1 auth-error-text"
          >
            {{ authError.message }}
          </div>
        </div>

        <div class="auth-or-divider">
          <hr />
          <span i18n>or</span>
          <hr />
        </div>

        <form
          #signInForm="ngForm"
          class="signin-form z-fx-col z-fx-gap-16 z-fx-between-stretch"
          (ngSubmit)="signInWithEmail(email, password)"
        >
          <div class="signup-inputs z-fx-col z-fx-gap-8 z-fx-auto">
            <mat-form-field
              class="auth-input-field"
              [class.auth-error-shake]="authError?.code === AuthError.INVALID_EMAIL"
            >
              <input
                matInput
                name="email"
                type="email"
                placeholder="Email..."
                i18n-placeholder="placeholder|sign-in email input"
                [(ngModel)]="email"
              />
            </mat-form-field>

            <span *ngIf="authError?.code === AuthError.INVALID_EMAIL" class="zef-text-p1 auth-error-text" i18n>
              Invalid email address
            </span>

            <mat-form-field
              class="auth-input-field"
              [class.auth-error-shake]="authError?.code === AuthError.NO_PASSWORD_MATCH"
            >
              <input
                matInput
                name="password"
                type="password"
                placeholder="Password..."
                i18n-placeholder="placeholder|sign-in password input"
                [(ngModel)]="password"
              />
            </mat-form-field>

            <auth-error-message [error]="AuthError.NO_PASSWORD_MATCH" i18n>
              Email and password do not match
            </auth-error-message>
            <span
              *ngIf="authError?.code === AuthError.PASSWORD_PROVIDER_ERROR && authError.message"
              class="zef-text-p1 auth-error-text"
            >
              {{ authError.message }}
            </span>
          </div>

          <button mat-raised-button color="ink" type="submit" class="zef-stretch-button email-signin" i18n>
            Log in with email
          </button>

          <a class="zef-text-p2 zef-hyper-link zef-color-muted" [inlineDialogTriggerFor]="resetPasswordDialog" i18n>
            Forgot password?
          </a>
        </form>
      </div>
    </div>
  </div>

  <zef-inline-dialog #resetPasswordDialog="inlineDialog" color="primary" (close)="onDialogClose()">
    <ng-container *dialogTitle i18n>Reset password</ng-container>

    <div class="z-fx-col z-fx-gap-24" *dialogContent>
      <ng-container *ngIf="sent$ | async; else emailForm">
        <span class="zef-text-p1" i18n>
          If we find an account registered with
          <strong>{{ email }}</strong>
          , you’ll soon receive a password-reset link.
        </span>

        <button
          mat-raised-button
          color="secondary"
          class="zef-stretch-button"
          (click)="resetPasswordDialog.closeDialog()"
          i18n
        >
          OK
        </button>
      </ng-container>

      <ng-template #emailForm>
        <span class="zef-text-p1" i18n>Enter the email address, and we'll send you a link to reset your password.</span>

        <div class="z-fx-col z-fx-gap-8">
          <mat-form-field
            class="auth-input-field auth-input-shadow"
            [class.auth-error-shake]="authError?.code === AuthError.PASSWORD_RESET_ERROR"
          >
            <input
              matInput
              name="email"
              type="email"
              placeholder="Email..."
              i18n-placeholder="placeholder|sign-in email input"
              [(ngModel)]="email"
            />
          </mat-form-field>

          <span *ngIf="authError?.code === AuthError.PASSWORD_RESET_ERROR" class="zef-text-p1 auth-error-text" i18n>
            Invalid email address
          </span>

          <button
            mat-raised-button
            color="primary"
            class="zef-stretch-button"
            [disabled]="sending$ | async"
            [class.loading]="sending$ | async"
            (click)="signInResetPassword(email)"
            i18n
          >
            Send reset link
          </button>
        </div>
      </ng-template>
    </div>
  </zef-inline-dialog>
</div>
