import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InviteData } from '@shared/models/account.model';

@Component({
  selector: 'auth-invite-found',
  styleUrls: ['./invite-found.component.scss'],
  templateUrl: './invite-found.component.html',
})
export class InviteFound {
  showAll = false;
  @Input() invites: InviteData[] = [];
  @Output() submit: EventEmitter<string> = new EventEmitter();

  submitInvite(inviteKey: string) {
    this.submit.next(inviteKey);
  }
}
