import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Store } from '@ngxs/store';
import { InviteData } from '@shared/models/account.model';
import { AuthenticationError } from '@shared/states/auth.actions';

@Component({
  selector: 'auth-google-signup',
  templateUrl: './google-signup.component.html',
})
export class GoogleSignup {
  readonly AuthError = AuthError;

  @Input() email: string;
  @Input() invites: InviteData[] = [];
  @Output() submit: EventEmitter<string | null> = new EventEmitter();

  constructor(private store: Store) {}

  clearError() {
    this.store.dispatch(new AuthenticationError(null));
  }

  finishSignUp(consent: boolean, inviteKey?: string) {
    if (!consent) {
      this.store.dispatch(new AuthenticationError(AuthError.NO_GDPR_CONSENT));
    } else {
      this.submit.emit(inviteKey);
    }
  }
}
