<div class="z-fx-col z-fx-gap-24 z-fx-no-shrink">
  <span class="zef-text-b2" i18n>Enter the email the report was shared with…</span>

  <div class="signup-inputs z-fx-col z-fx-gap-8 z-fx-auto">
    <mat-form-field class="auth-input-field" [class.auth-error-shake]="authError?.code === AuthError.INVALID_EMAIL">
      <input
        matInput
        name="email"
        type="email"
        placeholder="Email..."
        i18n-placeholder="placeholder|sign-in email input"
        [disabled]="autoload"
        (input)="clearError()"
        (blur)="rememberEmail(cb.checked, email)"
        [(ngModel)]="email"
      />
    </mat-form-field>

    <span *ngIf="authError?.code === AuthError.INVALID_EMAIL" class="zef-text-p1 auth-error-text" i18n>
      Please enter a valid email
    </span>
    <span *ngIf="authError?.code === AuthError.NO_REPORT_EMAIL_MATCH" class="zef-text-p1 auth-error-text" i18n>
      The email and share-link do not match. Please check your email and make sure you’ve copied the complete link.
    </span>
  </div>

  <mat-checkbox
    #cb
    color="primary"
    [checked]="!!remember"
    [disabled]="autoload"
    (change)="rememberEmail($event.checked, email)"
  >
    <span class="zef-text-p1" i18n>Remember me on this device</span>
  </mat-checkbox>

  <button
    mat-raised-button
    color="ink"
    type="submit"
    class="zef-stretch-button"
    [class.loading]="autoload"
    (click)="signInForReport(email)"
  >
    <span *ngIf="!autoload" i18n>Continue</span>
    <span *ngIf="autoload" i18n>Authenticating...</span>
  </button>
</div>
