<div class="z-fx-col z-fx-gap-32 z-fx-auto">
  <div class="z-fx-col z-fx-gap-8">
    <span class="zef-text-h1" i18n>Finish signing up...</span>

    <span class="zef-text-p2 zef-color-muted">{{ email }}</span>
  </div>

  <div class="z-fx-col z-fx-gap-8">
    <auth-gdpr-consent #gdpr (change)="clearError()"></auth-gdpr-consent>

    <auth-error-message [error]="AuthError.NO_GDPR_CONSENT" i18n>Please confirm</auth-error-message>
  </div>

  <auth-invite-found
    *ngIf="invites.length > 0"
    [invites]="invites"
    (submit)="finishSignUp(gdpr.checkbox.checked, $event)"
  ></auth-invite-found>

  <button
    *ngIf="invites.length === 0"
    mat-raised-button
    i18n
    color="ink"
    class="zef-stretch-button"
    (click)="finishSignUp(gdpr.checkbox.checked)"
  >
    Finish Signing Up
  </button>
</div>
