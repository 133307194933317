<div class="z-fx-col z-fx-gap-32 z-fx-auto">
  <span class="zef-text-h1" i18n>Confirm your email...</span>
  <img
    *ngIf="!isDesktop"
    src="assets/images/use-desktop.png"
    width="240"
    alt="Please use desktop"
    i18n-alt="use desktop image alt text"
  />
  <div class="zef-text-p1">
    <span i18n>
      Click the verification link we’ve sent to
      <strong>{{ email }}</strong>
      to complete registration.
    </span>
  </div>
  <span class="zef-text-p1" i18n>
    Make sure you’re on a
    <strong>laptop</strong>
    or a
    <strong>desktop</strong>
    when you continue.
  </span>
  <a class="zef-text-p1 zef-hyper-link" [class.disabled]="loading$ | async" (click)="resendEmail()" i18n>
    Resend verification link
  </a>
</div>
