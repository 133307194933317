<div class="z-fx-col z-fx-gap-48 z-fx-auto">
  <div class="z-fx-col z-fx-gap-8">
    <span *ngIf="!isInvite" class="zef-text-h1" i18n>Create new account</span>
    <span *ngIf="isInvite" class="zef-text-h2" i18n>{{ email }}</span>
    <a class="zef-text-p2 zef-hyper-link zef-color-muted" (click)="cancelSignUp()" i18n>Change sign up method</a>
  </div>

  <div class="z-fx-col z-fx-gap-24">
    <div class="z-fx-col z-fx-gap-8">
      <mat-form-field class="auth-input-field zef-input-combo" [authErrorShake]="AuthError.INVALID_EMAIL">
        <mat-icon matPrefix [class.zef-color-ink-lightest]="!email">email_address</mat-icon>

        <input
          #email1
          matInput
          id="signup-email"
          name="email"
          type="email"
          placeholder="Email..."
          i18n-placeholder="placeholder|sign-up email input"
          [disabled]="isInvite"
          [value]="email || ''"
          (input)="clearError()"
        />
      </mat-form-field>

      <auth-error-message [error]="AuthError.INVALID_EMAIL" i18n>Enter a valid email address</auth-error-message>

      <auth-passwords #password="AuthPasswords"></auth-passwords>
    </div>

    <div class="z-fx-col z-fx-gap-8">
      <auth-gdpr-consent #gdpr (change)="clearError()"></auth-gdpr-consent>

      <auth-error-message [error]="AuthError.NO_GDPR_CONSENT" i18n>Please confirm</auth-error-message>
    </div>
  </div>

  <button
    mat-raised-button
    i18n
    color="ink"
    class="zef-stretch-button"
    [loading]="loading"
    (click)="finishSignUp(gdpr.checkbox.checked, email1.value, password.data)"
  >
    Create Account
  </button>
</div>
