import { Component, Input } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Select, Store } from '@ngxs/store';
import { InviteData } from '@shared/models/account.model';
import { UserInfo } from '@shared/models/auth.model';
import { AuthState } from '@shared/states/auth.state';
import { Observable } from 'rxjs';
import { SignOutWithRedirect } from '@shared/states/auth.actions';

@Component({
  selector: 'auth-invite-error',
  templateUrl: './invite-error.component.html',
})
export class InviteError {
  readonly AuthError = AuthError;
  @Input() authError: { code: AuthError; message: string } | null = null;

  @Input() inviteData: InviteData | null = null;

  @Select(AuthState.info)
  readonly userInfo$!: Observable<UserInfo>;
  constructor(private store: Store) {}

  logout() {
    this.store.dispatch(new SignOutWithRedirect(true)).subscribe(() => {
      window?.location.reload();
    });
  }
}
