import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthError } from '@auth/auth.enum';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { isEmail } from 'class-validator';

@Component({
  selector: 'auth-report-signin',
  templateUrl: './report-signin.component.html',
})
export class ReportSignin implements OnInit {
  public email: string = '';
  @LocalStorage('remember') remember!: string | undefined;

  readonly AuthError = AuthError;
  @Input() authError: { code: AuthError; message: string } | null = null;

  @Output() select = new EventEmitter();

  public autoload = !!this.remember && isEmail(this.remember);

  constructor(
    private route: ActivatedRoute,
    private ls: LocalStorageService,
  ) {}

  ngOnInit(): void {
    if (this.autoload) {
      this.email = this.remember;
    }
  }

  signInForReport(email: string) {
    const linkKey = this.route.snapshot.params?.linkKey;

    this.select.next({ email, linkKey });
  }

  clearError() {
    this.select.next({});
  }

  rememberEmail(save: boolean, email: string) {
    if (save && !!email && isEmail(email)) {
      this.remember = email;
    } else {
      this.ls.clear('remember');
    }
  }
}
