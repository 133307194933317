<mat-checkbox class="zef-text-p2" color="primary" (change)="change.emit($event.checked)">
  <ng-container i18n>I agree to Zeffi’s</ng-container>
  <a class="zef-text-p2-link" href="https://zef.fi/terms-of-service/" target="_blank" i18n i18n-href>
    Terms of Service
  </a>
  ,
  <ng-container i18n>and</ng-container>
  <a class="zef-text-p2-link" href="https://zef.fi/data-processing-addendum/" target="_blank" i18n i18n-href>
    Data Processing Addendum
  </a>
</mat-checkbox>
