import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'auth-cancel-signup',
  templateUrl: './cancel-signup.component.html',
})
export class CancelSignup {
  @Output() submit: EventEmitter<void> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
}
