<div class="z-fx-col z-fx-gap-32 z-fx-auto">
  <div class="z-fx-col z-fx-gap-8">
    <mat-icon color="success">bullet_check</mat-icon>
    <span class="zef-text-h1" i18n>Email verified</span>

    <span class="zef-text-p2 zef-color-muted">{{ email }}</span>
  </div>

  <auth-invite-found *ngIf="invites.length > 0" [invites]="invites" (submit)="finishSignUp($event)"></auth-invite-found>

  <button
    *ngIf="invites.length === 0"
    mat-raised-button
    class="zef-placeholder-button"
    color="primary"
    (click)="finishSignUp(null)"
  >
    <div class="z-fx z-fx-gap-16 z-fx-w100 z-fx-sc">
      <div>
        <mat-icon>plus</mat-icon>
      </div>
      <span i18n>New Organization</span>
    </div>
  </button>
</div>
