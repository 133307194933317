import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Store } from '@ngxs/store';
import { AuthenticationError } from '@shared/states/auth.actions';

@Component({
  selector: 'auth-signin-sso',
  templateUrl: './signin-sso.component.html',
})
export class SigninSSO {
  public email: string = '';

  readonly AuthError = AuthError;
  @Input() authError: { code: AuthError; message: string } | null = null;
  @Output() select = new EventEmitter();

  constructor(private store: Store) {}

  signInWithSSO() {
    // this.select.next({ method: 'sso' });
  }

  clearError() {
    this.store.dispatch(new AuthenticationError(null));
    this.authError = null;
  }

  changeMethod() {
    this.select.next({ method: 'init' });
  }
}
