import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Store } from '@ngxs/store';
import { InviteData } from '@shared/models/account.model';

@Component({
  selector: 'auth-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.scss'],
})
export class EmailVerified {
  readonly AuthError = AuthError;
  submittted = false;

  @Input() email: string;
  @Input() invites: InviteData[] = [];
  @Output() submit: EventEmitter<string | null> = new EventEmitter();

  constructor(private store: Store) {}

  finishSignUp(inviteKey?: string) {
    this.submittted = true;
    this.submit.emit(inviteKey);
  }
}
