import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { GoToSignIn } from '@shared/states/auth.actions';

@Component({
  selector: 'auth-account-exist',
  templateUrl: './account-exist.component.html',
})
export class AccountExist {
  readonly environment = environment;

  sentResetRequest = false;

  @Input() email: string;
  @Output() submit: EventEmitter<string | undefined> = new EventEmitter();

  constructor(readonly store: Store) {}

  signIn() {
    this.store.dispatch(new GoToSignIn());
  }
}
