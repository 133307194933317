<div class="z-fx-col z-fx-gap-48 z-fx-auto">
  <p class="zef-text-h1" i18n>Account already exists</p>

  <div class="z-fx-col z-fx-gap-24">
    <div class="zef-highlight mat-warning">
      <span class="zef-text-p1" i18n>
        Looks like an account with
        <strong>{{ email }}</strong>
        already exists.
      </span>
    </div>

    <div class="z-fx-col z-fx-gap-16">
      <button routerLink="/login" mat-raised-button color="ink" class="zef-stretch-button" i18n>
        Log into existing account
      </button>

      <a class="zef-text-p1 zef-hyper-link button-signup" (click)="submit.emit()" i18n>Use different email</a>
    </div>
  </div>
</div>
