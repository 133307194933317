import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { InviteData } from '@shared/models/account.model';
import { AuthManager } from '@shared/services/auth-manager.service';
import { AcceptTeamInvite, SwitchTeam } from '@shared/states/account.actions';
import { AuthState } from '@shared/states/auth.state';
import { filter, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'auth-invite-accept',
  templateUrl: './invite-accept.component.html',
  styleUrls: ['./invite-accept.component.scss'],
})
export class InviteAccept implements OnInit {
  inviteData: InviteData | null = null;
  @Input() invite: InviteData | null = null;

  constructor(
    readonly store: Store,
    private am: AuthManager,
  ) {}

  ngOnInit() {
    this.inviteData = this.invite || ({} as any);
    const { teamKey, $key } = this.inviteData;

    if (this.inviteData && teamKey && $key) {
      this.store.dispatch(new AcceptTeamInvite(teamKey, $key));
    }

    this.store
      .select(AuthState.isInviteAccepted)
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.am.refreshToken()),
      )
      .subscribe(() => {
        console.log('Invite accepted for team', teamKey);

        if (teamKey) {
          this.store.dispatch(new SwitchTeam(teamKey));
        } else {
          window.location.replace('/');
        }
      });
  }
}
