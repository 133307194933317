<div class="auth-sidebar-content flex flex-col gap-32">
  <div class="flex flex-auto flex-col gap-48">
    <div class="flex flex-col gap-8">
      <span class="zef-text-h2" i18n>Log in to existing account</span>

      <div class="flex gap-8">
        <a class="zef-text-p2 cursor-pointer text-ink-lighter underline" (click)="changeMethod()" i18n>
          Change log in method
        </a>
      </div>
    </div>

    <div *ngIf="authError?.code === AuthError.SSO_LOGIN_ERROR && authError.message" class="flex flex-col gap-24">
      <div class="flex flex-col gap-16">
        <div class="zef-text-p1 zef-highlight mat-primary">
          <span i18n>Unknown error</span>
          :
          {{ authError.message }}
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-8">
      <mat-form-field class="auth-input-field zef-input-combo" [authErrorShake]="AuthError.INVALID_EMAIL">
        <mat-icon matPrefix [class.zef-color-ink-lightest]="!email">email_address</mat-icon>
        <input
          matInput
          name="email"
          type="email"
          placeholder="Enter email..."
          i18n-placeholder
          [(ngModel)]="email"
          (input)="clearError()"
        />
      </mat-form-field>

      <auth-error-message [error]="AuthError.INVALID_EMAIL" i18n>Invalid email address</auth-error-message>

      <button mat-raised-button color="ink" type="submit" class="zef-stretch-button" (click)="signInWithSSO()" i18n>
        Log in
      </button>
    </div>
  </div>
</div>
