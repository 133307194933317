<div class="z-fx-col z-fx-gap-32 z-fx-auto">
  <span *ngIf="authError?.code === AuthError.INVITE_WRONG_ACCOUNT" class="zef-highlight mat-primary zef-text-p1" i18n>
    The invitation link seems to belong to a different email address. Log out and try using the link again.
  </span>
  <span *ngIf="authError?.code === AuthError.INVITE_KEY_EXPIRED" class="zef-highlight mat-warning zef-text-p1" i18n>
    Oops, the invitation link does not seem to be working anymore.
  </span>
  <span *ngIf="authError?.code === AuthError.INVITE_KEY_INVALID" class="zef-highlight mat-warning zef-text-p1" i18n>
    Oops, the invitation link does not look right. Did you paste all of it?
  </span>

  <mat-divider></mat-divider>

  <ng-container *ngIf="userInfo$ | async as user">
    <div class="z-fx-col z-fx-center-center z-fx-gap-16">
      <strong *ngIf="user.email" class="zef-text-p1">{{ user.email }}</strong>
      <a
        *ngIf="inviteData?.email && authError?.code === AuthError.INVITE_WRONG_ACCOUNT"
        (click)="logout()"
        class="zef-text-p1 zef-hyper-link"
        i18n
      >
        Log out
      </a>
      <a *ngIf="user.email && user.emailVerified" class="zef-text-p1 zef-hyper-link" routerLink="/" i18n>
        Back to my account
      </a>
      <a *ngIf="user.email && !user.emailVerified" class="zef-text-p1 zef-hyper-link" routerLink="/logout" i18n>
        Login
      </a>
      <a *ngIf="!user.email" class="zef-text-p1 zef-hyper-link" routerLink="/login" i18n>Login</a>
    </div>
  </ng-container>
</div>
