import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'auth-gdpr-consent',
  templateUrl: './gdpr-consent.component.html',
  styleUrls: ['./gdpr-consent.component.scss'],
})
export class GdprConsent {
  @ViewChild(MatCheckbox) checkbox: MatCheckbox;
  @Output() change: EventEmitter<boolean> = new EventEmitter();
}
