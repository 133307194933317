import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PasswordData } from '@auth/auth-passwords/auth-passwords.component';
import { AuthError } from '@auth/auth.enum';
import { Store } from '@ngxs/store';
import { AuthenticationError } from '@shared/states/auth.actions';
import { isEmail } from 'class-validator';

@Component({
  selector: 'auth-email-signup',
  templateUrl: './email-signup.component.html',
  styleUrls: ['./email-signup.component.scss'],
})
export class EmailSignup {
  readonly AuthError = AuthError;

  @Input() email: string = '';

  @Input() loading: boolean;
  @Input() isInvite = false;

  @Output() submit: EventEmitter<{ email?: string; password?: string; error?: any }> = new EventEmitter();

  constructor(private store: Store) {}

  finishSignUp(consent: boolean, email: string, pwdata: PasswordData) {
    // console.warn(consent, email, pwdata);

    if (!email || !isEmail(email)) {
      this.store.dispatch(new AuthenticationError(AuthError.INVALID_EMAIL));
    } else if (!pwdata.isStrong) {
      this.store.dispatch(new AuthenticationError(AuthError.PASSWORD_TOO_WEAK));
    } else if (!pwdata.isMatched) {
      this.store.dispatch(new AuthenticationError(AuthError.PASSWORD_MISMATCH));
    } else if (!consent) {
      this.store.dispatch(new AuthenticationError(AuthError.NO_GDPR_CONSENT));
    } else {
      this.store.dispatch(new AuthenticationError(null));
      const password = pwdata.value;
      this.submit.emit({ email, password });
    }
  }

  clearError() {
    this.store.dispatch(new AuthenticationError(null));
  }

  cancelSignUp() {
    this.submit.emit(null);
  }
}
