<div class="z-fx-col z-fx-gap-32 z-fx-auto">
  <p class="zef-text-h1" i18n>Cancel creating the account?</p>
  <p class="zef-text-p1" i18n>This would set Zeffi to a clean state. You can create a new account later.</p>

  <div class="z-fx-col z-fx-gap-8">
    <button mat-raised-button i18n color="primary" class="zef-stretch-button" (click)="submit.emit()">
      Continue creating account
    </button>
    <a class="zef-hyper-link zef-color-alert" (click)="cancel.emit()" i18n>Cancel signup</a>
  </div>
</div>
