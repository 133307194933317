<div *ngIf="!!inviteData" class="z-fx-col z-fx-gap-32 z-fx-auto">
  <div class="z-fx-col z-fx-gap-8">
    <span class="zef-text-h2" i18n>{{ inviteData.email }}</span>
    <span class="zef-text-p2 zef-color-muted" i18n>Just a few seconds…</span>
  </div>

  <div *ngIf="inviteData.teamKey" class="z-fx-col z-fx-gap-8">
    <team-tile
      variant="small"
      [teamName]="inviteData.teamName"
      [teamLogo]="inviteData.teamLogo"
      [teamCreated]="0"
    ></team-tile>

    <span class="zef-text-p1" i18n>
      Accepting invitation from
      <strong>{{ inviteData.userName }}</strong>
      to join their organization.
    </span>
  </div>
</div>

<zef-spinner size="large"></zef-spinner>
